import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/horizontal-timeline/horizontal_timeline.2.0_v2.0.5.2.min.css'
import './styles/horizontal-timeline/JavaScript/horizontal_timeline.2.0_v2.0.5.2.min'
import './styles/styles.scss';
import Slick from "vue-slick";
import $ from "jquery"
import Swiper from "swiper";
import VueCarousel from "vue-carousel";
import VueMeta from 'vue-meta';
import Vue from 'vue'
import * as VueAos from 'vue-aos'
import lazyPlugin from 'vue3-lazy'

const app = createApp(App)

app.component('slick', Slick) // global registration - can be used anywhere
app.component('$', $) // global registration - can be used anywhere
app.component('swiper', Swiper) // global registration - can be used anywhere
app.component('vue-carousel', VueCarousel) // global registration - can be used anywhere
app.component('VueMeta', VueMeta) // global registration - can be used anywhere
app.component('VueAos', VueAos) // global registration - can be used anywhere

app.use(lazyPlugin, {
    loading: 'loading.png',
    error: 'error.png'
  });

app.use(router).mount('#app')
