<template>
  <div class="container-fluid background">
    <div  class="form">
      <div class="row pamodzi-red-background">
        <div class="col-md-6 p-0">
          <img src="../../assets/images/contact/oldBuilding.png" class="img-fluid w-100" alt="Pamodzi Building">
        </div>
        <div class="col-md-6 my-auto background py-5  pamodzi-cream">
          <h1 class="atf-heading pa text-uppercase mt-md-n0 mt-n4">Contact Us</h1>
          <div class="row py-3">
            <div class="col-md-6">
              <address>
                Physical Address : <br>
                <div class="pl-3">
                  1 Hoop Avenue <br>
                  President Place <br>
                  Rosebank <br>
                  Johannesburg <br>
                  South Africa <br>
                  2196
                </div>
              </address>
            </div>
            <div class="col-md-6">
              <address>
                Postal Address: <br>
                <div class="pl-3">
                  Postnet Suite 77 <br>
                  Private Bag X1 <br>
                  Melrose Arch 2076 <br>
                  South Africa
                </div>
              </address>
            </div>
          </div>
          <div class="row pb-3 text-decoration-none">
            <div class="col-md-6">
              <a id="number" class="pamodzi-cream" href="tel:+27100722000">+27 10 072 2000</a>
            </div>
            <div class="col-md-6">
              <a id="emailAddress" class="pamodzi-cream" href="mailto:info@pamodzi.co.za">info@pamodzi.co.za</a>
            </div>
          </div>
          <form @submit.prevent="submitForm"> 
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="firstName">Name</label>
              <input type="text" required v-model="firstName" class="form-control"  name="firstName" id="firstName" placeholder="Name">
            </div>
            <div class="form-group col-md-6">
              <label for="lastName">Surname</label>
              <input type="text" required v-model="lastName" class="form-control" name="lastName" id="lastName" placeholder="Surname">
            </div>
          </div>
          <div class="form-row">
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="text" required v-model="email" class="form-control" name="email" id="email" placeholder="Email Address">
          </div>
          <div class="form-group col-md-6">
            <label for="phone">Phone Number</label>
            <input type="text" minlength="10" v-model="phone" class="form-control" name="phone" id="phone" placeholder="Phone Number">
          </div>
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea type="text" required v-model="message" class="form-control" cols="10" name="message" id="message" placeholder="Message"></textarea>
          </div>
          <button type="submit" class="btn rounded-pill font-weight-bolder pamodzi-cream-backgropund pamodzi-red">
            Contact Us
          </button>
        </form>
        <div v-if="emailPassed && responseMessage" class="alert alert-success alert-dismissible fade show mt-2" role="alert">
          {{responseMessage}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-if="!emailPassed && responseMessage" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
          {{responseMessage}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: "ContactForm",
  data(){
    return {
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        phone: '',
        responseMessage: "",
        emailPassed: false
    } 
  },
  methods: {
    async submitForm() {
      const formData = new FormData();
      formData.append("fname", this.firstName+ " " +this.lastName);
      formData.append("email", this.email);
      formData.append("message", this.message);
      formData.append("subject", "Website Enquiry")

      try {
        const response = await fetch("https://insync.co.za/apis/pamodziApi/sendEmail.php", {
          method: "POST",
          body: formData,
        });
        console.log(response)
        if (response.ok) {
          this.emailPassed = true;
          this.responseMessage = "Message sent successfully!";
          this.firstName= '',
          this.lastName= '',
          this.email= '',
          this.message= '',
          this.phone= ''
        } else {
          this.emailPassed = false;
          this.responseMessage = "Failed to send message.";
        }
      } catch (error) {
        console.error("Error:", error);
        console.log(error)
        this.responseMessage = "An error occurred.";
      }
    },
    //     checkForm: function () {
    //   if (this.firstName && this.lastName && this.email && this.message) {
    //     //https://insync.co.za/apis/pamodziApi/sendEmail.php
    //       axios.post('https://insync.co.za/apis/pamodziApi/sendEmail.php', {
    //         name: this.firstName,
    //         email:this.email,
    //         message: this.message
    //       })
    //       .then((response) => {
    //         this.feedData = response.data.channel.item;
    //         this.logResults(this.feedData);
    //       }, (err) => {
    //       })
    //     // return true;
    //   }

    //   this.errors = [];

    //   if (!this.firstName) {
    //     this.errors.push('Name Field is required.');
    //   }
    //   if (!this.lastName) {
    //     this.errors.push('Surname Field is required.');
    //   }
    //   if (!this.email) {
    //     this.errors.push('Email Field is required.');
    //   }
    //   if (!this.message) {
    //     this.errors.push('Message Field is required.');
    //   }
    // }
  }
};
</script>

<style scoped lang="scss">
.background {
  background-position: 20px;
  background-image: url("../../assets/images/shared/backgroundLogo.svg");
  background-repeat: no-repeat;
}

a{
  &:hover {
    color: #d0d0d0;
  }
}

img{
  object-fit: cover;
  height: 100%;
}
</style>
