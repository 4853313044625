<template>
  <div class="container-fluid p-0 py-0 my-0">
    <Navbar></Navbar>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/shared/Navbar";
import Footer from "@/components/shared/Footer";

export default {
  components: {Footer, Navbar},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Pamodzi',
    // all titles will be injected into this template
    titleTemplate: 'Pamodzi | %s',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1', lang: 'en'}
    ]
  }
}
</script>
<style lang="scss">
:root {
  --carousel-color-primary: #9B1D00 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.carousel__prev,
.carousel__next {
  background:--carousel-color-primary ;
  box-sizing: content-box;
  border: 5px solid white;
}

</style>
